import React, { useEffect } from 'react';
import { Layout } from 'components';
import { container, content } from './404.module.scss';

const InventiaStaff = ({ location }) => {
  useEffect(() => {
    window.localStorage.setItem('inventia-staff', 'true');
    setTimeout(() => {
      window.location.href = process.env.GATSBY_SITE_URL;
    }, 5000);
  }, []);

  return (
    <Layout location={location} theme="light">
      <div className={`container ${container}`}>
        <div className={content}>
          <h2>Thanks for identifying as an Inventia employee.</h2>
          <p>Redirecting you to the homepage in 5 seconds…</p>
        </div>
      </div>
    </Layout>
  );
};

export default InventiaStaff;
